.outerContainer {
  width: 300px;
  height: 300px;
}

.innerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.statisticContainerOut {
  width: 400px;
  height: 300px;
  justify-content: center;
}

.marginH4 {
  margin: 15px 0 0 0;
  color: rgba(204, 214, 246, 255);
}

.educationPicture {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.educationText {
  margin: 0 0 15px 0;
  color: rgb(223, 227, 245);
}

.educationTextH {
  color: rgb(246, 246, 246);
}

.leftGraph {
  display: block;
  margin: 0 auto;
}

.rightGrap {
  min-height: 300px;
}

.EducationRightCaption {
  margin-top: 50px;
  text-align: center;
  background-color: rgb(24, 36, 68);
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
}

.hide {
  -webkit-animation: hide 0.1s linear forwards;
  animation: hide 0.1s linear forwards;
}

.show {
  -webkit-animation: show 1s linear forwards;
  animation: show 1s linear forwards;
}

.colorGreen {
  color: rgb(100, 255, 218);
}

@media screen and (max-width: 1280px) {
  .outerContainer {
    width: 200px;
    height: 200px;
  }

  .statisticContainerOut {
    width: 300px;
    height: 300px;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .outerContainer {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
  }

  .statisticContainerOut {
    width: 300px;
    height: 300px;
    justify-content: center;
  }

  .educationPicture {
    max-width: 320px;
  }

  .educationText {
    width: auto;
  }
}
