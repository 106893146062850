.profilePicture {
  z-index: 10;
  max-height: 590px;
}

.profilePictureContainer {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.profilePictureBackground {
  bottom: 0;
  position: absolute;
  z-index: 10;
  background-color: rgb(24, 36, 68);
  color: #ffffff;
  font-weight: bold;
  height: 400px;
  width: 550px;
  border-radius: 10px;
  box-shadow: rgba(100, 255, 218, 0.96) 0px 3px 10px 1px;
}

.aboutMeContainer {
  color: rgba(204, 214, 246, 255);
  padding: 20px 20px 20px 20px;
  text-align: left;
  cursor: default;
}

.colorGreen {
  color: rgb(100, 255, 218);
}

.aboutMeTypeWriterText {
  color: rgba(204, 214, 246, 255);
  font-size: 40px;
}

.aboutMeTypeWriterCursor {
  color: rgb(100, 255, 218);
  font-size: 40px;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.startingWord {
  color: rgba(204, 214, 246, 255);
  font-size: 40px;
  margin-right: 10px;
}

.typeWriterContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.aboutMeContent {
  margin-left: 0;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop30 {
  margin-top: 30px !important;
}

.marginTop15 {
  margin-top: 15px !important;
}

.marginTop50 {
  margin-top: 50px;
}

.inline {
  display: inline;
}

.happyEffect {
  color: rgb(100, 255, 218);
  position: relative;
  text-decoration: none;
}

.happyEffect::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: rgb(100, 255, 218);
  bottom: -4px;
  box-shadow: rgba(100, 255, 218, 0.4) 0px 5px,
    rgba(100, 255, 218, 0.3) 0px 10px, rgba(100, 255, 218, 0.2) 0px 15px,
    rgba(100, 255, 218, 0.1) 0px 20px, rgba(100, 255, 218, 0.05) 0px 25px;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.happyEffect:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

@media screen and (max-width: 1300px) {
  .profilePicture {
    z-index: 10;
    width: 270px;
  }

  .profilePictureBackground {
    height: 250px;
    width: 320px;
  }

  .aboutMeTypeWriterText {
    color: rgba(204, 214, 246, 255);
    font-size: 25px;
  }

  .aboutMeTypeWriterCursor {
    color: rgb(100, 255, 218);
    font-size: 25px;
  }

  .startingWord {
    font-size: 25px;
  }

  .marginBottom30 {
    padding-bottom: 30px;
  }
}
