.divContainerCenter {
  width: 100%;
  height: 600px;
  margin: 0 auto;
  position: relative;
  background-color: rgba(2, 12, 27, 0.4) !important;
  /* Fallback color */
  /* background-color: rgba(2, 12, 27, 0.4); /* Black w/opacity/see-through */
  border-radius: 25px;
}

.divContainerVerticalAlign {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;
  margin: 25px 25px 25px 25px;
}

.mainColor {
  color: white;
}

.myName {
  font-size: 50px;
  color: #fffafa;
}

.textNormal {
  font-size: 20px;
  color: #fffafa;
  text-align: left;
}

.textMiddle {
  font-size: 30px;
  color: #fffafa;
}

.textLeft {
  text-align: left;
  z-index: -1;
}

/* here the rules for small windows */
@media (max-width: 640px) {
  .profilePicture {
    width: 400px;
  }
}

/* here the rules for windows between 500px and 900px */
@media (min-width: 640px) and (max-width: 900px) {
  .profilePicture {
    width: 300px;
    text-align: left !important;
  }
}

.itemsVerticalCenter {
  display: flex;
  align-items: center;
}

.alignVertical {
  margin-top: 150px;
}

.transparent {
  background-color: red;
}