img.logos {
  transition: all 0.5s ease;
  height: 40px;
  margin: 0 10px 10px 0;
}

.container {
  text-align: left;
}

.h4customLine {
  line-height: 5px;
}

.outterStyles {
  text-align: center;
  background-color: rgb(24, 36, 68);
  padding: 35px 10px 35px 10px;
}

.cardOutterContainer {
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
  padding: 20px 10px;
}

.experience {
  font-size: 20px;
  text-align: center;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.cardContentContainer {
  flex-grow: 1;
  min-height: 250px;

  flex: 1;
  display: flex;
  flex-direction: column;
}

.textEffect {
  background: linear-gradient(to right, hsl(98 100% 62%), hsl(204 100% 59%));
  background-color: red;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.divProgressbars {
  bottom: 20%;
  transform: translateY(-20%);
  position: absolute;
  padding-right: 20px;
  width: 100%;
  text-align: left;
  color: rgba(204, 214, 246, 255);
}

.divProgressbar {
  padding: 5px 20px 5px 20px;
}

.divLogos {
  padding: 10px 10px 10px 10px;
}

.hide {
  -webkit-animation: hide 0.1s linear forwards;
  animation: hide 0.1s linear forwards;
}

.show {
  -webkit-animation: show 1s linear forwards;
  animation: show 1s linear forwards;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    width: 100% !important;
  }
}
