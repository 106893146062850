.projectsHeader {
  color: rgba(204, 214, 246, 255);
  animation: show 2s ease-in-out infinite;
  opacity: 1;
}

.headerIcon {
  position: relative;
  top: 5px;
  margin-left: 40px;
  margin-right: 40px;
}

.projectsContainer {
  width: 99%;
  padding: 50px 0;
  margin: 50px auto;
  position: relative;
  overflow-y: scroll;
  /*max-height: 3000px; */
}

.projectsContainer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: rgba(204, 214, 246, 255);
  z-index: 1;
}

.timelineBlock {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;
}

.timelineBlockRight {
  float: right;
}

.timelineBlockLeft {
  float: left;
  direction: rtl;
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #64ffda;
  margin-top: 10px;
  z-index: 9999;
}

.timelineContent {
  width: 95%;
  padding: 0 15px;
  color: #667;
}

@media screen and (max-width: 1024px) {
  .projectsContainer {
    /*max-height: 2500px; */
    overflow: hidden;
    width: 110%;
    margin-right: -16px !important;
    padding: 50px 0;
    margin: 20px auto;
  }

  .marker {
    z-index: 1;
  }

  .projectsContainer:before {
    left: 8px;
    width: 2px;
  }

  .timelineBlock {
    width: 100%;
    margin-bottom: 30px;
  }

  .timelineBlockFirst {
    margin-top: -50px !important;
  }

  .timelineBlockRight {
    float: none;
  }

  .timelineBlockLeft {
    float: none;
    direction: ltr;
  }

  .projectsCardHeaderLeft {
    text-align: left !important;
  }

  .projectsCardContentLeft {
    text-align: left !important;
  }

  .betweenContentsMinus100 {
    margin-top: auto !important;
  }

  .betweenContentsPlus200 {
    margin-top: auto !important;
  }

  .betweenContentsMinus400 {
    margin-top: auto !important;
  }

  .betweenContentsMinus200 {
    margin-top: auto !important;
  }

  .betweenContentsMinus300 {
    margin-top: auto !important;
  }
}

.projectsCardOutterContainer {
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
  padding: 20px 10px;
}

.projectsOutterStyles {
  text-align: center;
  background-color: rgb(24, 36, 68);
  padding: 15px 15px 15px 15px;
}

.projectsCardContentContainer {
}

.projectsCardHeaderCenter {
  color: #64ffda;
  text-align: center;
}

.projectsTextHeaderEffect {
}

.projectsCardContentLeft {
  color: rgba(204, 214, 246, 255);
  text-align: left;
}

.projectsCardContentRight {
  color: rgba(204, 214, 246, 255);
  text-align: left;
}

.betweenContentsMinus400 {
  margin-top: -400px;
}

.betweenContentsMinus200 {
  margin-top: -200px;
}

.betweenContentsMinus300 {
  margin-top: -300px;
}

.betweenContentsMinus100 {
  margin-top: -100px;
}

.betweenContentsPlus100 {
  margin-top: 100px;
}

.contentText {
  direction: ltr !important;
}

.contentShortDescriptionBox {
  margin: 20px 0 20px 0;
}

.contentHighlights {
  direction: ltr !important;
}

.highlightButton {
  margin: 0 10px 10px 0 !important;
}

.contentShortDescriptionBox span {
  margin: 5px 5px 0 5px;
}

.contentShortDescriptionItem {
  margin: 5px 0 0 0;
  direction: ltr !important;
}

.iconCenter {
  position: relative;
  top: 5px;
}

.technologyButton {
  margin: 10px 0 10px 10px !important;
  height: 5px !important;
}
