.divLeftVertical {
  text-align: left !important;
}

.divCenterContainer {
  width: 100%;
  height: 600px;
  margin: 0 auto;
  position: relative;
}

.divContainerVerticalAlign {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;
  margin: 25px 25px 25px 25px;
}

.contactTextEffectTitle {
  color: rgba(100, 255, 218, 0.96);
}

.contactHeaderIcon {
  font-size: 200%;
}

.getInTouchContent {
  color: rgba(204, 214, 246, 255);
  text-shadow: 20px;
}

.contactSubCardsContent {
  margin-top: 30px;
  color: rgba(204, 214, 246, 255);
  text-shadow: 20px;
}

.outterStyleGetInTouch {
  text-align: center;
  background-color: transparent;
  padding: 35px 10px 35px 10px;
}

.contactCardOutterContainer {
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
  padding: 20px 10px;
}

.contactOutterStyles {
  text-align: center;
  background-color: rgb(24, 36, 68);
  padding: 35px 10px 35px 10px;
}

.contactCardContentContainer {
  flex-grow: 1;
  min-height: 250px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contactCardHeader {
  background-color: transparent;
}

.contentIcons {
  margin-top: 25px;
  font-size: 150%;
}

.contentIcon {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}

.contactIcon:hover {
  filter: drop-shadow(0px 0px 10px rgba(100, 255, 218, 0.96))
    drop-shadow(0px 0px 20px rgba(100, 255, 218, 0.96))
    drop-shadow(0px 0px 56px rgba(100, 255, 218, 0.96));
}

.typeWriterContactContainer {
  text-align: center;
}

.colorGreen {
  color: rgb(100, 255, 218);
}

.getInTouchContentHeader {
  margin-top: 50px;
}

.contactTypeWriterText {
  color: rgba(204, 214, 246, 255);
  font-size: 40px;
}

.contactTypeWriterCursor {
  color: rgb(100, 255, 218);
  font-size: 40px;
}

.contactMeTypeWriterText {
  color: rgba(204, 214, 246, 255);
  font-size: 40px;
}

.contactMeTypeWriterCursor {
  color: rgb(100, 255, 218);
  font-size: 40px;
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}