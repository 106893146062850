.menuVerticalCenter {
  position: absolute;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menuColor {
  background-color: transparent;
}

.menuVericalBottom {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.logoContainer {
  width: 100%;
  text-align: left;
  margin-top: 10px;
}

.profileLogo {
  width: 70px;
  /*border-radius: 40%;
    border: 3px solid white;
    */
  border-radius: 20%;
  display: block;
  clear: both;
  outline: 2px solid white;
  transition: 0.7s all ease-in;
}

.profileLogo:hover {
  outline-color: rgba(100, 255, 218, 0.96);
}

.contactLogos {
  width: 100%;
  border-radius: 30%;
  filter: grayscale(100%);
}

.contactLogos:hover {
  filter: grayscale(0%);
  box-shadow: rgba(100, 255, 218, 0.96) 0px 54px 55px,
    rgba(100, 255, 218, 0.96) 0px -12px 30px,
    rgba(100, 255, 218, 0.96) 0px 4px 6px,
    rgba(100, 255, 218, 0.96) 0px 12px 13px,
    rgba(100, 255, 218, 0.96) 0px -3px 5px;
}

/* Circle Animation */

#showContacts {
  width: 90px;
  height: 150px;
  position: absolute;
  margin-top: -150px;
  margin-left: -20px;
  list-style: none;
}

.menu-button:hover {
  outline-color: rgba(100, 255, 218, 0.96);
}

.menu-button {
  opacity: 0;
  z-index: -1;
  outline: 2px solid white;
  transition: outline-color 0.7s ease-in;
}

.menu-button {
  width: 50px;
  height: 70px;
  position: absolute;
  left: 0%;
  top: -10%;
  /*border-radius: 20%;*/
  background: rgb(24, 36, 68);
  background-size: 100%;
  text-decoration: none;
  border-radius: 0 150px 150px 0;
}

#showContacts:not(:target) > a:first-of-type,
#showContacts:target > a:last-of-type {
  opacity: 1;
  z-index: 1;
}

.menu-item {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 30%;
  margin: -50px 0 0 -50px;
  border-radius: 20%;
  background-color: transparent;
  transition: transform 500ms;
  z-index: 0;
  transition: 0.5s;
}

#showContacts:target > .menu-item:nth-child(4) {
  transform: rotate(-60deg) translateX(70px) translateY(-50px) rotate(60deg);
}

#showContacts:target > .menu-item:nth-child(3) {
  transform: rotate(-20deg) translateX(75px) translateY(-40px) rotate(20deg);
}

#showContacts:target > .menu-item:nth-child(5) {
  transform: rotate(20deg) translateX(70px) translateY(-30px) rotate(-20deg);
}

#showContacts:target > .menu-item:nth-child(6) {
  transform: rotate(-60deg) translateX(-25px) translateY(75px) rotate(60deg);
}

#showContacts:target > .menu-item:nth-child(7) {
  transform: rotate(-60deg) translateX(-80px) translateY(35px) rotate(60deg);
}

.content {
  position: absolute;
  text-align: center;
  margin: -10px 0 0 -30px;
  top: 70%;
  left: 50%;
  font-size: 20px;
}

.textOnButton {
  position: relative;
  left: 10%;
  z-index: 1;
  color: white;
  font-size: 110% !important;
  top: 23%;
}

.textOnButtonIconBefore {
  transition: opacity 0.5s ease-in, width 1.5s ease-out !important;
}

.textOnButtonIconAfter {
  fill: #85cee9 !important;
  transition: opacity 0.5s ease-in, width 1.5s ease-out !important;
}

/* Contact menu when used small screen or mobile */

@media screen and ((max-width: 768px) or (max-height: 768px)) {
  .menu-button {
    width: 0;
    height: 0;
    visibility: none;
    visibility: hidden;
  }

  .menu-item {
    width: 70px;
    height: 70px;
    margin-right: 40px;
    margin-top: 0px;
    position: relative;
    display: inline-block;
  }

  #showContacts {
    margin-bottom: 0;
    padding: 0;
    position: relative;
    margin-left: -35%;
    width: 200px;
    height: 100px;
  }
}

@media screen and (max-height: 650px) {
  .menu-button {
    width: 0;
    height: 0;
    visibility: none;
    visibility: hidden;
  }

  .menu-item {
    width: 50px;
    height: 50px;
    margin-right: 40px;
    margin-top: 100px;
    position: relative;
    display: inline-block;
  }

  #showContacts {
    margin-bottom: 0;
    padding: 0;
    position: relative;
    margin-left: -35%;
    width: 200px;
    height: 10px;
  }
}

label {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

label span {
  background: #fff;
  border-radius: 10px;
  height: 7px;
  margin: 7px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

span:nth-of-type(1) {
  width: 50%;
}

span:nth-of-type(2) {
  width: 100%;
}

span:nth-of-type(3) {
  width: 75%;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked ~ span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(8px, 0px);
}

input[type="checkbox"]:checked ~ span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(-45deg);
}

input[type="checkbox"]:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(30px, -11px) rotatez(45deg);
}
